import { forwardRef, Ref, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGSVGElement>

const Prosus = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => (
  <svg viewBox="0 0 90 90" ref={ref} {...props}>
    <g fill="#1136A8" fillRule="evenodd">
      <path d="m16.103 9.296 2.535 1.854c4.256 3.109 8.512 6.217 12.767 9.327l14.683 10.734c3.416 2.497 10.46 7.653 10.46 7.653l4.49-3.28v13.765l-4.487 3.278L16.1 23.054l.002-13.758ZM25.194 9.323c.373-.264.697-.527 1.042-.78 2.568-1.872 5.143-3.736 7.7-5.624 2.013-1.486 4.27-2.35 6.728-2.723 1.66-.252 3.324-.26 4.986-.033 1.686.23 3.305.695 4.84 1.444.98.479 1.894 1.057 2.718 1.768 1.46 1.261 2.54 2.773 2.936 4.697.412 2-.037 3.829-1.167 5.506-.712 1.057-1.628 1.917-2.675 2.635a8.95 8.95 0 0 0-.127.093s-.092-.07-.12-.089c-3.199-2.043-6.713-2.715-10.447-2.41a15.166 15.166 0 0 0-5.763 1.63c-.369.189-.727.4-1.075.626-.072.046-.183.136-.183.136l-9.393-6.876ZM57.501 32.95l-9.49-6.93.865-.623c2.958-2.126 5.941-4.218 8.866-6.39 2.112-1.567 3.695-3.58 4.55-6.103.175-.516.275-1.056.408-1.585.028-.11.05-.185.074-.295.016-.04.065-.057.085-.027.02.055.024.105.022.22.001 4.051.026 8.103-.011 12.154-.023 2.56-.948 4.82-2.517 6.826a14.91 14.91 0 0 1-2.658 2.613c-.057.044-.117.085-.194.14" />
      <g>
        <g transform="translate(0 58.774)">
          <mask id="a" fill="#fff">
            <path d="M0 .024h12.908v18.919H0z" />
          </mask>
          <path
            d="m6.486 10.73-4.348-.012V2.16h4.345a4.29 4.29 0 0 1 4.287 4.284 4.291 4.291 0 0 1-4.284 4.285M6.483.024H0v18.919h2.136v-6.088l4.347.012a6.43 6.43 0 0 0 6.425-6.422c0-3.54-2.882-6.421-6.425-6.421"
            mask="url(#a)"
          />
        </g>
        <path d="M63.692 65.577a4.062 4.062 0 0 1-4.059 4.056 4.062 4.062 0 0 1-4.058-4.056l.007-6.803h-2.123v6.803a6.18 6.18 0 0 0 6.174 6.17 6.18 6.18 0 0 0 6.175-6.17v-6.803h-2.12l.004 6.803Z" />
        <g transform="translate(40.485 58.617)">
          <mask id="b" fill="#fff">
            <path d="M.088.042h10.184v13.126H.088z" />
          </mask>
          <path
            d="M8.978 6.569c-.823-.613-1.996-.983-3.505-1.35C3.08 4.645 2.895 4.136 2.895 3.48c0-.856.812-1.367 2.172-1.367 1.238 0 2.28.442 3.187 1.351l.301.302 1.388-1.642-.26-.256C8.412.622 6.932.041 5.022.041 2.42.041.604 1.483.604 3.548c0 2.318 1.552 3.144 4.418 3.857 2.517.588 2.96 1.109 2.96 1.986 0 1.391-1.463 1.683-2.69 1.683-1.382 0-2.46-.502-3.394-1.58l-.263-.302-1.548 1.411.211.286c1.054 1.427 2.913 2.28 4.97 2.28 3.04 0 5.003-1.492 5.003-3.8 0-1.253-.41-2.143-1.294-2.8"
            mask="url(#b)"
          />
        </g>
        <path d="M76.709 65.146c-.821-.613-1.991-.983-3.497-1.35-2.388-.574-2.573-1.083-2.573-1.74 0-.856.81-1.367 2.168-1.367 1.235 0 2.275.442 3.18 1.352l.3.302L77.67 60.7l-.26-.255c-1.266-1.247-2.744-1.828-4.649-1.828-2.595 0-4.407 1.443-4.407 3.507 0 2.32 1.549 3.145 4.408 3.858 2.51.589 2.952 1.11 2.952 1.988 0 1.391-1.46 1.683-2.684 1.683-1.377 0-2.453-.502-3.385-1.58l-.262-.303-1.544 1.412.21.285c1.052 1.428 2.906 2.28 4.959 2.28 3.032 0 4.991-1.491 4.991-3.8 0-1.253-.41-2.143-1.291-2.8M36.457 65.182a4.418 4.418 0 0 1-4.413 4.413 4.418 4.418 0 0 1-4.413-4.413 4.418 4.418 0 0 1 4.413-4.413 4.418 4.418 0 0 1 4.413 4.413m-4.413-6.565a6.573 6.573 0 0 0-6.565 6.565 6.573 6.573 0 0 0 6.565 6.565 6.573 6.573 0 0 0 6.565-6.565 6.573 6.573 0 0 0-6.565-6.565M21.857 58.617c-3.519 0-6.382 2.643-6.382 6.394l.005 6.58h2.128V70.1l.007.008v-4.886c0-2.827 1.988-4.421 4.423-4.421.551 0 1.078.102 1.565.287V58.83a6.562 6.562 0 0 0-.906-.155 6.419 6.419 0 0 0-.84-.057" />
      </g>
    </g>
  </svg>
))

export default Prosus
