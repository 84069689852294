import { forwardRef, Ref, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGSVGElement>

const Sequoia = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => (
  <svg viewBox="0 0 180 24" ref={ref} {...props}>
    <defs>
      <clipPath id="clip-path">
        <path style={{ fill: 'none' }} d="M0 0h180v24H0z" />
      </clipPath>
    </defs>
    <g style={{ clipPath: 'url(#clip-path)' }}>
      <path
        style={{ fill: '#1b1917' }}
        d="m63.65 23.76-2.31-4c1.08-2.07 1.08-4.51 1.08-7.72 0-4 0-7-2.31-9.43A8.07 8.07 0 0 0 53.81 0a8.46 8.46 0 0 0-6.3 2.61C45.2 4.93 45.2 7.87 45.2 12s-.12 7 2.31 9.42a8.38 8.38 0 0 0 2.87 2 8.24 8.24 0 0 0 3.43.61 9.13 9.13 0 0 0 1.95-.24Zm-12.86-5.15c-.84-1-1.08-2.08-1.08-6.61s.29-5.64 1.08-6.61a4.21 4.21 0 0 1 1.34-.9 4 4 0 0 1 1.57-.32 4.09 4.09 0 0 1 1.58.32 4.17 4.17 0 0 1 1.33.9c.84 1 1.08 2.08 1.08 6.61s-.24 5.63-1.08 6.61a3.93 3.93 0 0 1-1.32.92 4.15 4.15 0 0 1-1.59.29 3.9 3.9 0 0 1-1.58-.3 4 4 0 0 1-1.33-.91ZM116.83.24h4.47v23.52h-4.47ZM8.49 24C5 24 2.32 23.27 0 20.94L2.91 18a7.7 7.7 0 0 0 5.58 2c2.67 0 4.12-1 4.12-2.94a2.74 2.74 0 0 0-.17-1.06 2.57 2.57 0 0 0-.56-.9 3.61 3.61 0 0 0-2.06-.86L7 13.85a7.43 7.43 0 0 1-4.47-2 6.46 6.46 0 0 1-1.28-2.14A6.32 6.32 0 0 1 .86 7.2C.84 3.06 3.87.12 9 .12c3.15 0 5.58.72 7.53 2.69l-2.94 2.83A6.24 6.24 0 0 0 8.85 4C6.42 4 5.22 5.36 5.22 7a2.55 2.55 0 0 0 .72 1.7 4.61 4.61 0 0 0 2.19 1l2.79.36a6.77 6.77 0 0 1 4.47 1.85 7 7 0 0 1 1.42 2.32 7.15 7.15 0 0 1 .41 2.71c0 4.51-3.87 7.09-8.74 7.09M23.75 23.76V.24h15.39v4h-10.8v5.55h9.2V14h-9.19v5.77h10.8v4h-15.4M77.81 24c-4.74 0-8.61-3.3-8.61-8.32V.24h4.63v15.3c0 2.7 1.59 4.28 4 4.28s4-1.61 4-4.28V.24h4.47v15.42c0 5-3.72 8.32-8.46 8.32M107.86 21.42a8.07 8.07 0 0 1-6.3 2.6 8.36 8.36 0 0 1-6.29-2.6C93 19.09 93 16.15 93 12s-.12-7 2.32-9.42A8 8 0 0 1 101.56 0a8.64 8.64 0 0 1 6.3 2.6c2.31 2.33 2.31 5.27 2.31 9.42s0 6.98-2.31 9.4Zm-3.39-16a4.09 4.09 0 0 0-5.81 0c-.85 1-1.08 2.08-1.08 6.61s.23 5.63 1.08 6.6a4.07 4.07 0 0 0 5.81 0c.84-1 1.08-2.07 1.08-6.6s-.21-5.67-1.08-6.64ZM142.78 23.76l-1.33-4.16h-8.25l-1.44 4.16H127L135.52.24H139l8.49 23.52Zm-5.47-16.67-2.91 8.57h5.82ZM174.18.24H180v5.88h-5.82ZM159.63 18.7V.24h-2.91v21.43ZM161.67 20.82l-2.91 2.94H180v-2.94ZM165.45 12.85V.24h-2.91v15.55ZM167.49 14.94l-2.92 2.94H180v-2.94ZM171.26 7V.24h-2.91v9.67ZM173.31 9.06l-2.9 2.94H180V9.06Z"
      />
    </g>
  </svg>
))

export default Sequoia
