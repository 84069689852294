import { forwardRef, Ref, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGSVGElement>

const Omnivore = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    x="0"
    y="0"
    viewBox="0 0 192.3 50.6"
    enableBackground="new 0 0 192.3 50.6"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <g>
      <g>
        <path
          d="M26.1 50.2c0-11.1 7.2-20.5 17.2-23.7 2.4-.8 4.9-1.2 7.6-1.2h.1c-6.4-4.9-14.4-7.8-23-7.8-10.4 0-19.8 4.2-26.6 11 1.5 12.2 12 21.7 24.7 21.7"
          style={{ fill: '#034a3c' }}
        />
      </g>
      <path
        d="M51 25.3c-2.7 0-5.2.4-7.6 1.2-10 3.2-17.2 12.6-17.2 23.7C40 50.2 51.1 39 51.1 25.3H51z"
        style={{ fill: '#82b841' }}
      />
      <path
        d="M51.1 25.3C51.1 11.5 39.9.4 26.2.4c-13.8 0-25 11.1-25 24.9 0 1.1.1 2.1.2 3.1 6.8-6.8 16.2-11 26.6-11 8.7.1 16.7 3 23.1 7.9"
        style={{ fill: '#6dc6da' }}
      />
      <path
        style={{ fill: '#006355' }}
        d="M15.7 48c0-13.8 11.2-24.9 24.9-24.9 3.7 0 7.2.8 10.4 2.3-6.4-4.9-14.4-7.8-23-7.8-10.4 0-19.8 4.2-26.6 11C2.5 37.1 8.1 44.4 15.7 48"
      />
    </g>
    <g>
      <g>
        <g>
          <g>
            <path
              style={{ fill: '#006355' }}
              d="M67.4 17c1.2 0 2.3.2 3.3.6s1.8.9 2.5 1.6 1.2 1.6 1.6 2.6.6 2.2.6 3.4c0 1.3-.2 2.4-.6 3.4s-.9 1.9-1.6 2.6-1.5 1.3-2.5 1.7-2.1.6-3.3.6c-1.2 0-2.3-.2-3.3-.6-1-.4-1.8-.9-2.5-1.7-.7-.7-1.2-1.6-1.6-2.6-.4-1-.6-2.2-.6-3.4 0-1.3.2-2.4.6-3.4.4-1 .9-1.9 1.6-2.6.7-.7 1.5-1.3 2.5-1.6 1-.4 2.1-.6 3.3-.6zm0 13.6c1.3 0 2.3-.4 3-1.3.6-.9 1-2.2 1-3.9 0-1.7-.3-3.1-1-4-.6-.9-1.6-1.4-3-1.4s-2.4.5-3 1.4-1 2.2-1 3.9.3 3 1 3.9c.6.9 1.6 1.4 3 1.4z"
            />
            <path
              style={{ fill: '#006355' }}
              d="M78.3 33.3v-16h2.4c.5 0 .8.2 1 .7l.3 1.2.9-.9c.3-.3.6-.5 1-.7.3-.2.7-.3 1.1-.4s.8-.2 1.3-.2c1 0 1.8.3 2.5.8s1.1 1.3 1.5 2.2c.3-.5.6-1 .9-1.4.4-.4.8-.7 1.2-.9.4-.2.9-.4 1.4-.5s1-.2 1.5-.2c.9 0 1.7.1 2.4.4.7.3 1.3.7 1.7 1.2.5.5.8 1.2 1.1 1.9.2.8.4 1.6.4 2.6v10.2H97V23.1c0-1-.2-1.8-.7-2.3-.4-.5-1.1-.8-2-.8-.4 0-.8.1-1.1.2-.3.1-.6.3-.9.6-.3.3-.5.6-.6 1s-.2.8-.2 1.3v10.2h-3.9V23.1c0-1.1-.2-1.9-.6-2.3-.4-.5-1.1-.7-1.9-.7-.6 0-1.1.1-1.6.4s-.9.7-1.4 1.1v11.7h-3.8z"
            />
            <path
              style={{ fill: '#006355' }}
              d="M104.6 33.3v-16h2.4c.5 0 .8.2 1 .7l.3 1.3c.3-.3.7-.6 1-.9.4-.3.7-.5 1.1-.7s.8-.3 1.3-.5c.5-.1 1-.2 1.5-.2.9 0 1.7.1 2.3.4.7.3 1.2.7 1.7 1.3.5.5.8 1.2 1 1.9.2.7.4 1.6.4 2.5v10.2h-3.9V23.1c0-1-.2-1.7-.7-2.3-.5-.5-1.1-.8-2-.8-.7 0-1.3.2-1.9.5s-1.1.7-1.7 1.2v11.6h-3.8z"
            />
            <path
              style={{ fill: '#006355' }}
              d="M127.1 12.6c0 .3-.1.6-.2.9-.1.3-.3.5-.5.8-.2.2-.5.4-.8.5s-.6.2-1 .2c-.3 0-.6-.1-.9-.2-.3-.1-.6-.3-.8-.5s-.4-.5-.5-.8-.2-.6-.2-.9c0-.3.1-.7.2-1s.3-.6.5-.8.5-.4.8-.5.6-.2.9-.2.7.1 1 .2c.3.1.6.3.8.5.2.2.4.5.5.8s.2.7.2 1zm-.6 4.7v16h-3.9v-16h3.9z"
            />
            <path
              style={{ fill: '#006355' }}
              d="M138.9 33.3h-3.5l-6.4-16h3.2c.3 0 .5.1.7.2s.3.3.4.5l3.1 8.6c.2.5.3 1 .4 1.5.1.5.2 1 .3 1.4.1-.5.2-1 .3-1.4.1-.5.3-1 .5-1.5l3.2-8.6c.1-.2.2-.4.4-.5s.4-.2.7-.2h3.1l-6.4 16z"
            />
            <path
              style={{ fill: '#006355' }}
              d="M153.9 17c1.2 0 2.3.2 3.3.6s1.8.9 2.5 1.6 1.2 1.6 1.6 2.6c.4 1 .6 2.2.6 3.4 0 1.3-.2 2.4-.6 3.4-.4 1-.9 1.9-1.6 2.6-.7.7-1.5 1.3-2.5 1.7s-2.1.6-3.3.6-2.3-.2-3.3-.6c-1-.4-1.8-.9-2.5-1.7-.7-.7-1.2-1.6-1.6-2.6-.4-1-.6-2.2-.6-3.4 0-1.3.2-2.4.6-3.4.4-1 .9-1.9 1.6-2.6.7-.7 1.5-1.3 2.5-1.6 1-.4 2.1-.6 3.3-.6zm0 13.6c1.3 0 2.3-.4 3-1.3.6-.9 1-2.2 1-3.9 0-1.7-.3-3.1-1-4-.6-.9-1.6-1.4-3-1.4s-2.4.5-3 1.4-1 2.2-1 3.9.3 3 1 3.9 1.7 1.4 3 1.4z"
            />
            <path
              style={{ fill: '#006355' }}
              d="M164.8 33.3v-16h2.3c.4 0 .7.1.8.2.2.1.3.4.3.8l.2 1.9c.6-1 1.2-1.8 2-2.3.8-.6 1.6-.9 2.6-.9.8 0 1.4.2 2 .5l-.5 2.9c0 .2-.1.3-.2.4-.1.1-.2.1-.4.1s-.4 0-.6-.1c-.3-.1-.6-.1-1.1-.1-.8 0-1.5.2-2.1.7-.6.4-1.1 1.1-1.5 1.9v10h-3.8z"
            />
            <path
              style={{ fill: '#006355' }}
              d="M184.3 17c1 0 1.9.2 2.8.5.8.3 1.6.8 2.2 1.4.6.6 1.1 1.4 1.4 2.3.3.9.5 1.9.5 3.1v.7c0 .2-.1.3-.1.5-.1.1-.2.2-.3.2s-.3.1-.4.1h-9.9c.1 1.6.6 2.9 1.3 3.6.8.8 1.8 1.2 3.1 1.2.6 0 1.2-.1 1.6-.2.5-.1.8-.3 1.2-.5.3-.2.6-.3.9-.5.3-.1.5-.2.7-.2s.3 0 .4.1l.3.3 1.1 1.4c-.4.5-.9.9-1.4 1.3-.5.3-1.1.6-1.7.8s-1.2.3-1.8.4c-.6.1-1.2.1-1.7.1-1.1 0-2.2-.2-3.1-.6-1-.4-1.8-.9-2.5-1.6-.7-.7-1.3-1.6-1.7-2.7s-.6-2.3-.6-3.7c0-1.1.2-2.1.5-3.1.4-1 .9-1.8 1.5-2.5.7-.7 1.5-1.3 2.4-1.7 1-.5 2.1-.7 3.3-.7zm0 2.8c-1.1 0-2 .3-2.6 1-.6.6-1 1.5-1.2 2.7h7.3c0-.5-.1-1-.2-1.4-.1-.4-.3-.8-.6-1.2-.3-.3-.6-.6-1.1-.8-.5-.2-1-.3-1.6-.3z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
))

export default Omnivore
