import Agfunder from '@/components/svg/Agfunder'
import LightRock from '@/components/svg/LightRock'
import Omnivore from '@/components/svg/Omnivore'
import Prosus from '@/components/svg/Prosus'
import Sequoia from '@/components/svg/Sequoia'
import Sofina from '@/components/svg/Sofina'
import STRINGS from '@/constants/strings'

const { LANDING_PAGE } = STRINGS

const landingPageAssetDir = '/assets/images/landing'

const landingPageData = [
  {
    eyebrow: LANDING_PAGE.SLIDE_ONE_EYEBROW,
    title: LANDING_PAGE.SEEDS_TO_MARKET,
    actions: [
      { label: LANDING_PAGE.KNOW_YOUR_SOIL, url: '/know-your-soil' },
      { label: LANDING_PAGE.AGRI_INPUT, url: '/agri-input' },
      {
        label: LANDING_PAGE.ADVISORY,
        url: '/advisory-farmers-helpline-and-support',
      },
      {
        label: LANDING_PAGE.HEALTH_AND_GROWTH,
        url: '/health-growth-yield-prediction',
      },
      {
        label: LANDING_PAGE.AGRI_OUTPUT,
        url: '/agri-output-Harvest-and-Market-Access',
      },
      {
        label: LANDING_PAGE.FARM_INTELLIGENCE,
        url: '/farm-intelligence-AI-ML-Analytics',
      },
      {
        label: LANDING_PAGE.FINANCE,
        url: '/Agricultural-Finance-Credit-Insurance',
      },
    ],
    bottomNavLabel: LANDING_PAGE.WHAT_WE_ARE_UPTO,
    webImage: `${landingPageAssetDir}/slider_one_web`,
    mobileImage: `${landingPageAssetDir}/slider_one_mobile`,
    imageAlt: LANDING_PAGE.SLIDE_ONE_ALT_TEXT,
  },
  {
    eyebrow: LANDING_PAGE.SLIDE_TWO_EYEBROW,
    title: LANDING_PAGE.BREAKING_RECORDS_REACHING_HEIGHTS,
    actions: [
      {
        label: LANDING_PAGE.READ_NEWSLETTER,
        url: 'https://mailchi.mp/agrevolution.in/dehaat-newsletter-2021-yields-of-the-year-ew5rfnrljd',
        external: true,
      },
    ],
    bottomNavLabel: LANDING_PAGE.YEAR_REWIND,
    webImage: `${landingPageAssetDir}/slider_two_web`,
    mobileImage: `${landingPageAssetDir}/slider_two_mobile`,
    imageAlt: LANDING_PAGE.SLIDE_TWO_ALT_TEXT,
  },
  {
    eyebrow: LANDING_PAGE.SLIDE_THREE_EYEBROW,
    title: LANDING_PAGE.INVESTOR_AND_PARTNERS,
    brandIcons: [
      {
        icon: <Omnivore />,
        url: 'https://www.omnivore.vc/portfolios/',
        brandName: LANDING_PAGE.LOGO('Omnivore'),
      },
      {
        icon: <Agfunder />,
        url: 'https://agfunder.com/portfolio/',
        brandName: LANDING_PAGE.LOGO('Agfunder'),
      },
      {
        icon: <Sequoia />,
        url: 'https://www.sequoiacap.com/india/companies/',
        brandName: LANDING_PAGE.LOGO('Sequoia'),
      },
      {
        icon: (
          <img
            src={`${landingPageAssetDir}/investors/FMO.webp`}
            alt={LANDING_PAGE.LOGO('FMO Enterpreneurial Development Bank')}
          />
        ),
        url: 'https://www.fmo.nl/',
        brandName: LANDING_PAGE.LOGO('FMO Enterpreneurial Development Bank'),
      },
      {
        icon: <Prosus />,
        url: 'https://www.prosus.com/companies',
        brandName: LANDING_PAGE.LOGO('Prosus'),
      },
      {
        icon: (
          <img
            src={`${landingPageAssetDir}/investors/rtp-global.webp`}
            alt={LANDING_PAGE.LOGO('FMO Enterpreneurial Development Bank')}
          />
        ),
        url: 'https://rtp.vc/news/prosus-ventures-leads-30-million-investment-in-indian-agritech-startup-dehaat/',
        brandName: LANDING_PAGE.LOGO('RTP Global'),
      },
      {
        icon: <LightRock />,
        url: 'https://www.lightrock.com/portfolio',
        brandName: LANDING_PAGE.LOGO('Lightrock'),
      },
      {
        icon: <Sofina />,
        url: 'https://www.sofinagroup.com/',
        brandName: LANDING_PAGE.LOGO('Sofina'),
      },
    ],
    bottomNavLabel: LANDING_PAGE.GLOBAL_LEADERS,
    webImage: `${landingPageAssetDir}/slider_three_web`,
    mobileImage: `${landingPageAssetDir}/slider_three_mobile`,
    imageAlt: LANDING_PAGE.SLIDE_THREE_ALT_TEXT,
  },
  {
    eyebrow: LANDING_PAGE.SLIDE_FOUR_EYEBROW,
    title: LANDING_PAGE.PHENOMENAL_OPPORTUNITIES,
    actions: [
      {
        label: LANDING_PAGE.READ_THE_ARTICLE,
        url: 'https://www.ey.com/en_in/news/2020/09/indian-agritech-start-ups-are-operating-in-a-market-with-an-estimated-potential-of-us-24-billion-dollars-by-2025',
      },
      {
        label: LANDING_PAGE.APPLY_NOW,
        url: 'https://apply.workable.com/agrevolution/',
      },
    ],
    bottomNavLabel: LANDING_PAGE.WE_ARE_HIRING,
    webImage: `${landingPageAssetDir}/slider_four_web`,
    mobileImage: `${landingPageAssetDir}/slider_four_mobile`,
    imageAlt: LANDING_PAGE.SLIDE_FOUR_ALT_TEXT,
  },
  {
    eyebrow: LANDING_PAGE.SLIDE_FIVE_EYEBROW,
    title: LANDING_PAGE.LATEST_AGRITECH_NEWS,
    actions: [
      {
        label: LANDING_PAGE.READ_BLOG,
        url: 'https://write.agrevolution.in/tagged/engineering',
      },
    ],
    bottomNavLabel: LANDING_PAGE.WHATS_IN_NEWS,
    webImage: `${landingPageAssetDir}/slider_five_web`,
    mobileImage: `${landingPageAssetDir}/slider_five_mobile`,
    imageAlt: LANDING_PAGE.SLIDE_FIVE_ALT_TEXT,
  },
]

export default landingPageData
