import FacebookSolid from '@/components/svg/FacebookSolid'
import Instagram from '@/components/svg/Instagram'
import Linkedin from '@/components/svg/Linkedin'
import Twitter from '@/components/svg/Twitter'
import styles from '@/styles/SideOptions.module.css'

export default function SideOptions() {
  return (
    <ul className="hidden md:block bg-white/10 rounded-l-2xl list-none px-[10px] py-4 absolute top-1/2 -translate-y-1/2 right-0 z-20">
      <li>
        <a
          target="_blank"
          href="https://www.facebook.com/DeHaatTM/"
          className="flex bg-neutral-50 rounded-full h-[30px] w-[30px] justify-center items-center overflow-hidden group hover:bg-facebook-blue transition-all duration-300 relative"
          title="Facebook"
          rel="noreferrer"
        >
          <FacebookSolid className="fill-light-grey" height={18} width={18} />
        </a>
      </li>
      <li className="mt-2.5">
        <a
          target="_blank"
          href="https://www.instagram.com/dehaat/?hl=en"
          className={`flex bg-neutral-50 rounded-full h-[30px] w-[30px] justify-center items-center overflow-hidden p-1.5 ${styles.instagram}`}
          title="Instagram"
          rel="noreferrer"
        >
          <Instagram className="fill-light-grey" />
        </a>
      </li>
      <li className="mt-2.5">
        <a
          target="_blank"
          href="https://twitter.com/DeHaatTM?s=09"
          className="flex bg-neutral-50 rounded-full h-[30px] w-[30px] justify-center items-center overflow-hidden p-1.5 hover:bg-twitter-blue transition-all duration-300"
          title="Twitter"
          rel="noreferrer"
        >
          <Twitter className="fill-light-grey" />
        </a>
      </li>
      <li className="mt-2.5">
        <a
          target="_blank"
          href="https://www.linkedin.com/company/dehaat/"
          className="flex bg-neutral-50 rounded-full h-[30px] w-[30px] justify-center items-cente overflow-hidden group p-1 hover:bg-linkedin-blue transition-all duration-300"
          title="LinkedIn"
          rel="noreferrer"
        >
          <span className="bg-light-grey h-full w-full">
            <Linkedin className="fill-neutral-50 group-hover:fill-linkedin-blue transition-all duration-300" />
          </span>
        </a>
      </li>
    </ul>
  )
}
