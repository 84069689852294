import type { NextPage } from 'next'
import Head from 'next/head'

import LandingPageSlider from '@/components/landingPageSlider'
import Header from '@/components/navigation'
import SideOptions from '@/components/sideOptions'

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>
          DeHaat, From Seeds to Market | Online marketplace for farmers
        </title>
        <meta
          name="description"
          content="DeHaat connects farmers to suppliers and buyers on a single platform. Online marketplace providing all the agricultural products and services to farmers."
        />
      </Head>
      <main className="m-auto relative h-screen w-screen">
        <section className="fixed top-0 left-0 h-screen w-screen z-10">
          <LandingPageSlider />
        </section>
        <SideOptions />
        <section className="absolute top-0 left-0 w-screen z-20">
          <section className="m-auto max-w-7xl py-4 px-8">
            <Header theme="dark" />
          </section>
        </section>
      </main>
    </>
  )
}

export default Home
