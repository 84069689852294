import Link from 'next/link'
import { useCallback, useEffect, useRef, useState } from 'react'

import styles from '@/styles/LandingPage.module.css'

import landingPageData from './landingPageData'

const isExternalUrl = (url: string) =>
  url.startsWith('https://') || url.startsWith('http://')

const LandingPageSlider = () => {
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const timerRef = useRef<NodeJS.Timer>()

  const [activeIndex, setActiveIndex] = useState(0)
  const [pauseAutoPlay, setPauseAutoPlay] = useState(false)

  const onBottomNavClick = useCallback((index: number) => {
    setPauseAutoPlay(true)
    setActiveIndex(index)
  }, [])

  useEffect(() => {
    if (!pauseAutoPlay) {
      timerRef.current = setInterval(() => {
        setActiveIndex((prev) =>
          landingPageData.length - 1 === prev ? 0 : prev + 1
        )
      }, 3000)
    }
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [pauseAutoPlay])

  useEffect(() => {
    const delta = 100 / landingPageData.length
    const transitionTo = activeIndex * -1 * delta
    if (imageContainerRef.current) {
      imageContainerRef.current.style.transform = `translateX(${transitionTo}%)`
    }
  }, [activeIndex])

  useEffect(() => {
    if (pauseAutoPlay && timerRef.current) {
      clearInterval(timerRef.current)
    }
  }, [pauseAutoPlay])

  return (
    <div className="w-screen h-screen block overflow-hidden relative">
      <div
        style={{ width: `${landingPageData.length * 100}vw` }}
        className="transition-transform duration-300"
        ref={imageContainerRef}
      >
        {landingPageData.map((slide, index) => (
          <div
            key={slide.eyebrow}
            className="inline-flex h-screen w-screen relative items-center overflow-hidden"
            aria-hidden={activeIndex !== index}
          >
            <picture>
              <source
                srcSet={`${slide.webImage}.webp`}
                media="(min-width: 768px)"
                type="image/webp"
              />
              <source
                srcSet={`${slide.webImage}.jpeg`}
                media="(min-width: 768px)"
                type="image/jpeg"
              />
              <source srcSet={`${slide.mobileImage}.webp`} type="image/webp" />
              <source srcSet={`${slide.mobileImage}.jpeg`} type="image/jpeg" />
              <img
                src={`${slide.mobileImage}.jpeg`}
                alt={slide.imageAlt}
                className="absolute top-0 left-0 h-screen w-screen"
                loading="lazy"
              />
            </picture>
            <div
              className={`${styles.overlay} absolute top-0 left-0 h-screen w-screen`}
            />
            {/* Content of the slide */}
            <div
              className={`z-10 text-white p-[8%] md:max-w-[70%] ${
                activeIndex === index ? 'opacity-100' : 'opacity-0'
              } transition-all duration-300 delay-300`}
            >
              <p className="text-xs md:text-lg pb-2 text-white/50">
                {slide.eyebrow}
              </p>
              <p className="text-[40px] md:text-[64px] leading-none font-natosans-bold pb-2">
                {slide.title}
              </p>
              {(slide.actions || []).map(({ label, url }) =>
                isExternalUrl(url) ? (
                  <a
                    href={url}
                    key={label}
                    rel="noreferrer noopener"
                    className="text-sm md:text-base bg-green-color hover:bg-green-color/80 transition-all duration-300 px-5 py-2 rounded inline-block m-2"
                    target="_blank"
                  >
                    {label}
                  </a>
                ) : (
                  <Link
                    href={url}
                    key={label}
                    className="text-sm md:text-base bg-green-color hover:bg-green-color/80 transition-all duration-300 px-5 py-2 rounded inline-block m-2"
                  >
                    {label}
                  </Link>
                )
              )}
              {Array.isArray(slide.brandIcons) &&
              slide.brandIcons.length > 0 ? (
                <ul className="list-none flex flex-wrap">
                  {slide.brandIcons.map(({ icon, url, brandName }) => (
                    <li key={brandName}>
                      <a
                        href={url}
                        rel="noreferrer noopener"
                        aria-label={brandName}
                        target="_blank"
                        className="bg-white h-20 w-20 rounded-lg inline-flex justify-center items-center m-2 p-2 hover:scale-105 transition-all duration-300"
                      >
                        {icon}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      {/* Footer navigation */}
      <ul className="h-16 flex items-center list-none p-0 m-0 bottom-0 text-white absolute w-full justify-center z-10">
        {landingPageData.map(({ bottomNavLabel }, index) => (
          <li
            className={`h-full md:first:rounded-tl-2xl md:last:rounded-tr-2xl ${
              activeIndex === index ? 'md:bg-orange' : 'md:bg-white/10'
            } transition-all duration-300`}
            key={bottomNavLabel}
          >
            <button
              onClick={() => onBottomNavClick(index)}
              className="w-full h-full p-2 md:p-6 flex items-center justify-center"
              aria-label={bottomNavLabel}
            >
              <span className="hidden md:block text-xs font-natosans-medium">
                {bottomNavLabel}
              </span>
              <span
                className={`md:hidden rounded-full border h-6 w-6 transition-all duration-300 ${
                  activeIndex === index
                    ? 'bg-green-color border-green-color'
                    : 'bg-transparent border-white'
                }`}
              />
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default LandingPageSlider
