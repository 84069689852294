import { forwardRef, Ref, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGSVGElement>

const LightRock = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => (
  <svg viewBox="0 0 200 40" fill="none" ref={ref} {...props}>
    <path fill="#F6C142" d="M26.667 2 46 32 20 12l6.667-10z" />
    <path
      fill="#030303"
      fillRule="evenodd"
      d="M52.667 30.667 53.519 32h1.814L40 8l-3.915 6.128.883 1.325L40 10.667l12.667 20m0 0z"
      clipRule="evenodd"
    />
    <path
      fill="#030303"
      fillRule="evenodd"
      d="M13.333 20 26.667 0 48 32h-2l-.889-1.333h.01l-18.454-28-13.334 20-2.666-4-8 12h17.777l.89 1.333H0l10.667-16 2.666 4z"
      clipRule="evenodd"
    />
    <path
      fill="#030303"
      d="M66 32.078h1.87V4.666H66v27.41zM72.699 8.76c.823 0 1.496-.676 1.496-1.502 0-.789-.673-1.465-1.496-1.465s-1.496.676-1.496 1.465c0 .826.673 1.502 1.496 1.502zm-.935 23.318h1.87V13.303h-1.87v18.774zm22.371-18.775v4.356c-1.534-2.891-4.414-4.769-8.042-4.769-5.349 0-9.687 4.243-9.687 9.8 0 5.558 4.338 9.8 9.687 9.8 3.628 0 6.508-1.877 8.041-4.768v3.454c0 4.28-2.767 7.022-7.443 7.022-4.114 0-6.209-1.652-7.181-3.642l-1.646.939C79.211 38.235 82.315 40 86.691 40c5.162 0 9.314-3.191 9.314-8.824V13.303h-1.87zm-7.93 17.385c-4.45 0-7.93-3.53-7.93-7.998s3.48-7.998 7.93-7.998c4.451 0 7.93 3.53 7.93 7.998 0 4.469-3.479 7.998-7.93 7.998zm22.271-17.798c-2.88 0-5.274 1.202-6.695 3.98V5.793h-1.87v26.285h1.87v-9.913c0-5.07 2.88-7.472 6.545-7.472 3.516 0 5.499 2.252 5.499 5.707v11.678h1.907V20.4c0-4.543-2.805-7.51-7.256-7.51zm20.252 2.253v-1.84h-5.573V8.046l-1.908.564v4.693h-4.114v1.84h4.114v12.203c0 4.281 2.581 5.482 7.481 4.732v-1.69c-3.815.338-5.573.263-5.573-3.042V15.143h5.573zm4.487 1.84v-3.68h-1.87v18.774h1.87V21.64c0-4.806 3.03-6.796 6.247-6.796V13.04c-2.544 0-4.975.977-6.247 3.943zm15.601 15.507c5.423 0 9.799-4.242 9.799-9.8 0-5.557-4.376-9.8-9.799-9.8-5.461 0-9.837 4.243-9.837 9.8 0 5.558 4.376 9.8 9.837 9.8zm0-1.802c-4.451 0-7.93-3.53-7.93-7.998s3.479-7.998 7.93-7.998c4.45 0 7.892 3.53 7.892 7.998 0 4.469-3.442 7.998-7.892 7.998zm20.995 1.802c3.665 0 6.807-1.877 8.303-4.768l-1.571-.939c-1.234 2.328-3.703 3.905-6.732 3.905-4.526 0-7.93-3.53-7.93-7.998s3.404-7.998 7.93-7.998c2.992 0 5.535 1.54 6.62 3.906l1.534-.902c-1.347-2.928-4.489-4.806-8.154-4.806-5.648 0-9.837 4.243-9.837 9.8 0 5.558 4.189 9.8 9.837 9.8zm24.856-.412-10.436-9.763 10.062-9.012h-2.619l-9.462 8.524V5.793h-1.871v26.285h1.871V22.84l9.911 9.236h2.544z"
    />
  </svg>
))

export default LightRock
