import { forwardRef, Ref, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGSVGElement>

const Agfunder = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => (
  <svg viewBox="0 0 137 49" ref={ref} {...props}>
    <defs>
      <clipPath id="a">
        <path clipRule="evenodd" d="M-80-16h1300v6356H-80z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path
        fill="#303030"
        d="M23.7 27s-4-6.4 3.2-10.4c5.8-3.1 10-.7 10-.7s-4.7.5-9 3.5c-4.5 3-4.2 7.6-4.2 7.6"
      />
    </g>
    <defs>
      <clipPath id="b">
        <path clipRule="evenodd" d="M-80-16h1300v6356H-80z" />
      </clipPath>
    </defs>
    <g clipPath="url(#b)">
      <path fill="#303030" d="M55 17.2h-6.3v4.3h6v2.3h-6v7h-3.2V14.7H55v2.5z" />
    </g>
    <defs>
      <clipPath id="c">
        <path clipRule="evenodd" d="M-80-16h1300v6356H-80z" />
      </clipPath>
    </defs>
    <g clipPath="url(#c)">
      <path
        fill="#303030"
        d="M71.6 24.4c0 2.2-.6 4-1.9 5.1a6.9 6.9 0 0 1-4.9 1.6 7 7 0 0 1-4.8-1.6c-1.3-1.1-2-2.9-2-5.1V15h3.2v9.7c0 1.3.3 2.2 1 2.9.6.7 1.5 1 2.6 1 1.2 0 2.1-.3 2.8-1 .6-.7.9-1.6.9-2.9V15h3v9.5z"
      />
    </g>
    <defs>
      <clipPath id="d">
        <path clipRule="evenodd" d="M-80-16h1300v6356H-80z" />
      </clipPath>
    </defs>
    <g clipPath="url(#d)">
      <path
        fill="#303030"
        d="M89.8 30.8H87L78.2 20v10.7h-3V15H78l8.6 10.4V14.9h3v15.9z"
      />
    </g>
    <defs>
      <clipPath id="e">
        <path clipRule="evenodd" d="M-80-16h1300v6356H-80z" />
      </clipPath>
    </defs>
    <g clipPath="url(#e)">
      <path
        fill="#303030"
        d="M105.6 28.2a7.6 7.6 0 0 0 2.1-5.4c0-2-.7-3.9-2.1-5.4a8.4 8.4 0 0 0-6.4-2.5h-5.8v15.9h5.8c2.7 0 4.8-.9 6.4-2.6zm-2.7-9c.9 1 1.3 2.1 1.3 3.6s-.4 2.7-1.3 3.7c-1 1.2-2.5 1.8-4.5 1.8h-1.8v-11h1.8c2 0 3.5.6 4.5 1.8z"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="f">
        <path clipRule="evenodd" d="M-80-16h1300v6356H-80z" />
      </clipPath>
    </defs>
    <g clipPath="url(#f)">
      <path
        fill="#303030"
        d="M120.5 30.8h-10V14.9h9.8v2.4h-6.6v4.2h6.4V24h-6.4v4.4h6.8v2.5z"
      />
    </g>
    <defs>
      <clipPath id="g">
        <path clipRule="evenodd" d="M124 14.9h13v15.9h-13z" />
      </clipPath>
    </defs>
    <g clipPath="url(#g)">
      <path
        fill="#303030"
        d="M133.5 30.8h3.5l-2.2-3.6-.5-.9c-1-1.6-2-2.7-2.7-3.2.8-.3 1.4-.8 1.9-1.5.4-.6.7-1.4.7-2.2 0-1.4-.5-2.5-1.4-3.3-1-.8-2.3-1.2-3.9-1.2h-5v15.9h3.2V24h.5c.6 0 1.1.2 1.5.6.6.4 1.4 1.4 2.3 2.9l2 3.3zm-3.3-13c.5.4.7 1 .7 1.7a2 2 0 0 1-.7 1.8 3 3 0 0 1-2 .6h-1v-4.6h1a3 3 0 0 1 2 .5z"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="h">
        <path clipRule="evenodd" d="M-80-16h1300v6356H-80z" />
      </clipPath>
    </defs>
    <g clipPath="url(#h)">
      <path
        fill="#00C853"
        d="M47 2.7c-1.7.8-4.4 3-4.6 5.4 1-.8 2.4-1.2 3.8-1.4 3.3-.5 6.4-3.4 6.6-3.1.1.3-2.4 3.7-5.3 4.2-1.2.2-3 .4-4.2 1l-1 .7h-.1l-1.4 1c.9-5.8-6.3-7.4-10.2-6.6 1.2 1.3 1.5 3 2.5 4.5.7 1 1.5 2 2.6 2.6.6.5 1.5.8 2.3.9.6 0 .9-.3 1-.9.2-.7-.3-1.3-.8-1.8l-1.5-1.6a8 8 0 0 0-1-.8l-2-1.1c.1-.2 1.2 0 1.3 0 .6.2 1.2.4 1.7.8a7 7 0 0 1 1.8 1.4c.5.6 1 1.3 1.3 2 .1.3.2.9 0 1.3 0 .5-.4.9-.6 1.3l-.7 1.4-.7 1.8-.3 1c-.8 1.9-1.9 4-3.3 5.5-1.7 2-4.2 3-6.5 4 1.4-.2 5.2-.9 7.2-2.8s3.2-4.6 4.3-7c.7-1.8 1.8-5.6 4-5.9 1.1-.1 2.5.4 3.6.4 1 0 2-.2 3-.5 1.8-.7 3.2-2 4.1-3.7.5-.9 1-2 1.3-3 .3-1 .5-2.8 1.2-3.7-3 1.6-6.3 1.4-9.3 2.7"
      />
    </g>
    <defs>
      <clipPath id="i">
        <path clipRule="evenodd" d="M-80-16h1300v6356H-80z" />
      </clipPath>
    </defs>
    <g clipPath="url(#i)">
      <path
        fill="#303030"
        d="M20.4 30.6c-1 1.1-1.3 2.2-1 3-.2 0-.3-.2-.5-.6l-3-7.3H7L4 33c-.2.6-.3.7-1 .7H.7c-.2 0-.7 0-.7-.5l.2-.7 9.3-23c.4-.8.7-1 1.6-1h1c1 0 1.2.5 1.6 1.3l8 19.8c-.5.2-1 .6-1.3 1zm-9-16.8L8 22.6h6.8l-3.5-8.8z"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="j">
        <path clipRule="evenodd" d="M-80-16h1300v6356H-80z" />
      </clipPath>
    </defs>
    <g clipPath="url(#j)">
      <path
        fill="#303030"
        d="M42 37.3c-.3-.7-.7-1.3-1.3-1.9-.6-.5-1.3-1-2.1-1.3-.8-.3-1.7-.5-2.7-.5h-8.5l-1.4-.4a3 3 0 0 1-1.1-.7c-.3-.3-.6-.7-.4-1.2.2-1 2-1.5 3-1.5h2.3c3.7-.2 6.8.2 9.5-2 4.4-3.7.4-10.2.4-10.2.2.2 0 1 0 1.3l-.3 2.3a7.7 7.7 0 0 1-1.9 4.4c-2.2 2.2-4.9 2.7-7.8 3-2.7.2-6.1.3-7.4 3.2a4 4 0 0 0 .1 3.5c1.6 3 6.4 1.9 9 1.9l4 .1 2.4.7c.5.3.9.7 1.2 1.3.4.6.5 1.3.5 2.1 0 1.3-.3 2.4-1.1 3.3-2.7 2.7-8.1 2.9-11.6 1.3-.9-.5-1.9-1.1-2.4-1.8a4 4 0 0 1-.8-2.6c.1-1.4.5-2.7.5-2.7l-1.5 1.5c-.4.6-.8 1.3-1 2.1-.2.9 0 1.8.4 2.6 1.9 2.8 6.1 3.2 9.1 3.2 4.3 0 8.4-1.5 10.5-5.4a6.6 6.6 0 0 0 .4-5.6"
      />
    </g>
  </svg>
))

export default Agfunder
